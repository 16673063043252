<template>
	<div>
		<div class="screen-title">{{ $t('About us') }}</div>
		<div class="container">
			<static-block-component page="about" position="top"/>
			<about-component/>
			<static-block-component page="about" position="bottom"/>
		</div>
	</div>
</template>

<script>
  import AboutComponent from "../league/components/main/AboutComponent";
  import StaticBlockComponent from "../components/StaticBlockComponent";
  export default {
    name: "AboutUsScreen",
    components: {AboutComponent, StaticBlockComponent}
  }
</script>

<style scoped lang="scss">
	@import "src/assets/css/colors";
	@import "src/assets/css/fonts";
	.container{
		font-family: $font-main;
		font-style: italic;
		font-weight: 400;
	}
	.screen-title {
		font-size: 1.5em;
		padding: 2em 0;
		background-color: $card-background;
		font-weight: bold;
		text-transform: uppercase;
	}
</style>