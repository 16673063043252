<template>
    <div id="section-about" class="container">
        <div class="about-wrapper">
            <div class="about-image-wrapper">
                <image-component class="card w-100 about-image" :path="about.img" alt="banner"/>
            </div>
            <div>
                <h4 class="mb-4">{{ about.title }}</h4>
                <p class="m-t3" v-html="about.text"></p>
            </div>
        </div>
    </div>

</template>

<script>
    import {mapState} from "vuex";
    import ImageComponent from "../../../components/base-components/ImageComponent";

    export default {
      name: "AboutComponent",
      components: {ImageComponent},
      data() {
            return {
                about: {
                    title: '',
                    text: '',
                    img: '',
                },
            }
        },
        mounted: function () {
            this.about.title = this.channelSettings.content['about-title'] ?? '';
            this.about.text = this.convertEditorJsonToHtml(this.channelSettings.content['about-text']) ?? '';
            this.about.img = this.channelSettings.content['about-img'] ?this.getImagePath(this.channelSettings.content['about-img']) : '';
        },
        computed: {
            ...mapState({
                channelSettings: state => state.channel.settings,
            })
        }
    }
</script>

<style lang="scss" scoped>
    @import "src/assets/css/colors";
    @import "src/assets/css/mixins";

    #section-about {
        color: $text-primary;
        text-align: left;
        padding: 1rem;
    }
    .about-wrapper{
        display: flex;
        gap: 3rem;
        padding-top: 3rem;
        @include media-max(sm) {
            flex-direction: column;
        }
    }

    h4 {
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 1.5;
        margin: 0 0 1.8rem 0;
    }
    p{
        font-size: 1rem;
        line-height: 1.8;
        margin-bottom: 1.8rem;

    }
    .col-centered{
        float: none;
        margin: 0 25%;
    }
    .about-image-wrapper{
        flex: 1 0 auto;
    }
    .about-image{
        max-width: 555px;
        @include ratio(calc(16 / 9));
        @include media-min(xxl) {
            max-width: 30vw;
        }
    }

</style>
