<template>
  <div class="faq-screen">
    <div class="faq-screen-title">{{ $t('FAQ') }}</div>
    <h1>FREQUENTLY ASKED QUESTIONS</h1>
    <div class="container">
      <static-block-component
        page="faq"
        position="top"
      ></static-block-component>
      <div class="static-text">{{ $t('FAQ top text') }}</div>
      <faq-component></faq-component>
      <static-block-component
        page="faq"
        position="bottom"
      ></static-block-component>
    </div>
  </div>
</template>

<script>
import FaqComponent from '../components/FaqComponent';
import StaticBlockComponent from '../components/StaticBlockComponent';

export default {
  name: 'FaqScreen',
  components: { FaqComponent, StaticBlockComponent },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/css/fonts';
.faq-screen .container {
  font-family: $font-main;
  font-style: italic;
  font-weight: 400;
}

.faq-screen-title {
  font-size: 1.5em;
  padding: 2em 0;
  background-color: rgba(245, 245, 245, 100);
  font-weight: bold;
  text-transform: uppercase;
}

h1 {
  font-size: 2em;
  font-weight: bold;
  text-transform: uppercase;
  margin: 2em auto 3em;

  &:after {
    content: '';
    display: block;
    width: 2%;
    min-width: 50px;
    height: 2px;
    background-color: black;
    margin: 0.5em auto;
  }
}
.static-text {
  text-align: left;
  padding-bottom: 3rem;
}
</style>
